import React, { useState, useEffect } from 'react';
// You need to import the CSS only once
import './assets/css/index.css';

const Footer = (props) => {
  return (
    <footer>
        © {new Date().getFullYear()}, GE HealthCare.<br />
        Theodora™ is a trademark of  GE HealthCare. GE is a trademark of General Electric Company used under trademark license.  <br />This  Offering is not available in all geographics. Please check with your local GE HealthCare representative for availability in your country.

    </footer>
  );
};

export default Footer;
