import logo from './logo.svg';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonShelter, faPersonDress, faNotesMedical, faUtensils, faRibbon, faPersonBooth, faHourglassHalf } from '@fortawesome/pro-light-svg-icons'

import React, { useState } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Title from './components/Title';
import Car from './components/Car';

import { Button, ConfigProvider, Space } from 'antd';


import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';





// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function App() {




  const cars = [
    {
      key: 'select',
      title: 'Select',
      body: 'Small footprint',
      rooms: [
        {
          key: 'node1',
          label: 'Pristina room',
          icon: <FontAwesomeIcon icon={faRibbon} />
        },
        {
          key: 'node2',
          label: 'Dressing room',
          icon: <FontAwesomeIcon icon={faPersonBooth} />
        },
      ],
    },
    {
      key: 'plus',
      title: 'Plus',
      body: 'Enhance comfort',
      rooms: [
        {
          key: 'node3',
          label: 'Break room',
          icon: <FontAwesomeIcon icon={faUtensils} />
        },
        
        {
          key: 'node4',
          label: 'Pristina room',
          icon: <FontAwesomeIcon icon={faRibbon} />
        },

        {
          key: 'node6',
          label: 'Dressing room',
          icon: <FontAwesomeIcon icon={faPersonBooth} />
        },

        {
          key: 'node5',
          label: 'Waiting room',
          icon: <FontAwesomeIcon icon={faHourglassHalf} />
        },
      ],
    },
    {
      key: 'elite',
      title: 'Elite',
      body: 'Comprehensive screening solution',
      rooms: [
           
        {
          key: 'node8',
          label: 'Medical consulting room',
          icon: <FontAwesomeIcon icon={faNotesMedical} />
        },
        {
          key: 'node7',
          label: 'Pristina room',
          icon: <FontAwesomeIcon icon={faRibbon} />
        },
        {
          key: 'node11',
          label: 'Dressing room',
          icon: <FontAwesomeIcon icon={faPersonBooth} />
        },
        {
          key: 'node6',
          label: 'Waiting room',
          icon: <FontAwesomeIcon icon={faHourglassHalf} />
        },
        
      ],
    }
  ]

  return (

    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#512e88',

      },
    }}
  >
    <div className="App">


      <Header />

      <Title h1="Theodora™" content="Breast care mobile screening solution" />

      <section id="content">
  
        <Swiper
         modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}

          breakpoints={{
            
            1200: {
              slidesPerView: 2,
            },
            1900: {
              slidesPerView: 3,
            }
          }}

        >



          {cars.map(function (car) {
            return (
              <SwiperSlide>


                <Car car={car} />

              </SwiperSlide>
            )
          })}



        </Swiper>

      </section>


      <Footer />



    </div>
      </ConfigProvider>

  );
}

export default App;
